import React from 'react';
import PropTypes from 'prop-types';
import CardSection from './CardSection';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

class StripeCheckoutForm extends React.Component {
  handleSubmit = async(onSuccess) => {
    const {
      stripeIntent,
      payMethod,
      stripe,
      elements,
      onError
    } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup(stripeIntent, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result.error) {
      // Dispaly result.error.message
      onError(result.error.message);
    } else {
      // Display success
      payMethod.card_token = result.setupIntent.payment_method;
      onSuccess(payMethod);
    }
  };

  render() {
    return (
      <div>
        <CardSection
          onError={this.props.onError}
          clearErrors={this.props.clearErrors} />
      </div>
    );
  }
}

const StripeForm = React.forwardRef((props, ref) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <StripeCheckoutForm
          {...props}
          ref={ref}
          elements={elements}
          stripe={stripe}
        />
      )}
    </ElementsConsumer>
  );
});

StripeCheckoutForm.propTypes = {
  payMethod: PropTypes.object.isRequired,
  stripeIntent: PropTypes.string.isRequired,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  onError: PropTypes.func,
  clearErrors: PropTypes.func,
};

export default StripeForm;
