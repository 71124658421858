import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import MaskedInput from 'react-maskedinput';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Button from './Button';
import Icon from 'shared/components/Icon';
import ImageModal from './ImageModal';
import Link from './Link';
import Spinner from './Spinner';
import StripeForm from '../stripe/StripeForm';

class PayMethodForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      routingErrorClass: null,
      accountNumErrorClass: null,
      accountNumConfErrorClass: null,
      isCheckHelpModalOpen: false,
      defaultCountry: this.props.config.pay_method_config.default_country_selection
    };

    this.achTabSelected = this.achTabSelected.bind(this);
    this.creditCardTabSelected = this.creditCardTabSelected.bind(this);
    this.plastiqTabSelected = this.plastiqTabSelected.bind(this);
    this.checkTabSelected = this.checkTabSelected.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.configureCreditCardClient = this.configureCreditCardClient.bind(this);
    this.handleCreditCardResponse = this.handleCreditCardResponse.bind(this);
    this.toggleCheckHelpModal = this.toggleCheckHelpModal.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleCheckImage = this.handleCheckImage.bind(this);
  }

  componentDidMount() {
    const newPayMethod = this.getNewPayMethod();
    this.setNewPayMethod(newPayMethod);
  }

  setNewPayMethod(newPayMethod, callback) {
    this.setState({ newPayMethod }, () => {
      this.props.onChange({
        newPayMethod,
        isNewPayMethodValid: this.isNewPayMethodValid(newPayMethod)
      });
      if (callback) callback();
    });
  }

  isNewPayMethodValid(newPayMethod) {
    if (this.isAch(newPayMethod)) {
      const typeValid = newPayMethod.ach_account_type.length > 0;
      const routingNumValid = newPayMethod.ach_routing_num.length > 0;
      const accountNumValid = newPayMethod.ach_account_num.length > 0;
      const confirmNumValid = newPayMethod.ach_account_num == newPayMethod.ach_account_num_confirmation;
      let valid = typeValid && routingNumValid && accountNumValid && confirmNumValid;

      if (this.props.config.pay_method_config.show_pay_method_payee_field) {
        const payeeNameValid = newPayMethod.payee_name.length > 0;
        valid = valid && payeeNameValid;
      }

      return valid;
    } else if (this.isCreditCard(newPayMethod)) {
      if (this.stripeEnabled()) return true;

      const nameValid = newPayMethod.name_on_card.length > 0;
      const addressValid = newPayMethod.address_line_1.length > 0;
      const countryValid = newPayMethod.country.length > 0;
      const stateValid = newPayMethod.state.length > 0;
      const cityValid = newPayMethod.city.length > 0;
      const zipValid = newPayMethod.zip_code.length > 0;
      const cardValid = this.isCardValid(this.state.newPayMethod);

      return nameValid && addressValid && countryValid && stateValid && cityValid && zipValid && cardValid;
    }
    else if (this.isCheck(newPayMethod)) {
      const checkFront = newPayMethod.checkFront;
      const checkRear = newPayMethod.checkRear;

      return checkFront && checkRear;
    }
    else if (this.isPlastiq(newPayMethod)) {
      return this.isEmailValid(newPayMethod.email);
    }
    else {
      return false;
    }
  }

  isCardValid(card) {
    if (this.props.config.pay_method_config.cc_iframe_enabled) {
      return true;
    }
    else {
      return card.credit_card_number.length > 0 &&
        card.cc_expiration.length > 0 &&
        card.ccv_number.length > 0;
    }
  }

  isEmailValid(email) {
    const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

    return emailRegex.test(email);
  }

  getNewPayMethod() {
    if (this.enabledTypesLength() == 1 && this.props.creditCardEnabled) {
      const newPayMethod = this.newCreditCardPayMethod();
      newPayMethod.country = this.state.defaultCountry;
      this.props.clearErrors();
      if (this.stripeEnabled()) {
        this.setNewPayMethod(newPayMethod);
      } else {
        this.setNewPayMethod(newPayMethod, this.configureCreditCardClient);
      }
      return newPayMethod;
    } else {
      return this.newAchPayMethod();
    }
  }

  enabledTypesLength() {
    const achEnabled = this.props.config.pay_method_config.ach_enabled;
    const checkEnabled = this.props.config.pay_method_config.check_enabled;
    const creditCardEnabled = this.props.creditCardEnabled;
    const plastiqEnabled = this.props.oneTimePayment && this.props.plastiqEnabled;
    const enabledTypes = [achEnabled, checkEnabled, creditCardEnabled, plastiqEnabled];
    return enabledTypes.filter((enabled) => enabled).length;
  }

  tabs() {
    const dropdownEnabled = this.enabledTypesLength() > 1;
    if (dropdownEnabled) {
      return (
        <div className="btn-group pay-method-type-btn-group">
          {this.payMethodToggle()}
          {this.payMethodTypeOptions()}
        </div>
      );
    }
  }

  payMethodToggle() {
    return (
      <Button
        aria-expanded="false"
        aria-haspopup="true"
        className="dropdown-toggle"
        data-toggle="dropdown"
        icon={this.getNewPayMethodIcon()}
        id="payMethodDropdownToggle"
        type="button"
      >
        {this.getNewPayMethodName()}{' '}
        <span className="caret" />
      </Button>
    );
  }

  payMethodTypeOptions() {
    return (
      <div aria-labelledby="payMethodDropdownToggle" className="dropdown-menu">
        {this.achOption()}
        {this.checkOption()}
        {this.creditCardOption()}
        {this.plastiqOption()}
      </div>
    );
  }

  achOption() {
    if (this.props.config.pay_method_config.ach_enabled) {
      return (
        <Link
          className="dropdown-item"
          onClick={this.achTabSelected}
          icon="bank"
        >
          {this.props.content.ach_label}
        </Link>
      );
    }
  }

  checkOption() {
    if (this.props.config.pay_method_config.check_enabled) {
      return (
        <Link className="dropdown-item" onClick={this.checkTabSelected}>
          {this.props.content.check_label}
        </Link>
      );
    }
  }

  creditCardOption() {
    if (this.props.creditCardEnabled) {
      return (
        <Link
          className="dropdown-item"
          onClick={this.creditCardTabSelected}
          icon="credit-card"
        >
          {this.props.content.credit_card_label}
        </Link>
      );
    }
  }

  plastiqOption() {
    if (this.props.plastiqEnabled && this.props.oneTimePayment) {
      return (
        <Link
          className="dropdown-item"
          onClick={this.plastiqTabSelected}
          icon="credit-card far"
        >
          {this.props.content.plastiq_label}
        </Link>
      );
    }
  }

  getNewPayMethodName() {
    if (this.state.newPayMethod) {
      switch (this.state.newPayMethod.type) {
        case "AchPayMethod":
          return this.props.content.ach_label;
        case "CreditCardPayMethod":
          return this.props.content.credit_card_label;
        case "CheckPayMethod":
          return this.props.content.check_label;
        case "PlastiqPayMethod":
          return this.props.content.plastiq_label;
      }
    }
  }

  getNewPayMethodIcon() {
    if (this.state.newPayMethod) {
      switch (this.state.newPayMethod.type) {
        case "AchPayMethod":
          return "bank";
        case "CreditCardPayMethod":
          return "credit-card";
        case "CheckPayMethod":
          return "";
        case "PlastiqPlayMethod":
          return "credit-card far";
      }
    }
  }

  isAch(payMethod) {
    return payMethod && payMethod.type == 'AchPayMethod';
  }

  isPlastiq(payMethod) {
    return payMethod && payMethod.type == 'PlastiqPayMethod';
  }

  isCreditCard(payMethod) {
    return payMethod && payMethod.type == 'CreditCardPayMethod';
  }

  isCheck(payMethod) {
    return payMethod && payMethod.type == 'CheckPayMethod';
  }

  stripeEnabled() {
    return this.props.stripeEnabled;
  }

  achTabSelected = () => {
    this.props.clearErrors();
    this.setNewPayMethod(this.newAchPayMethod());
  }

  creditCardTabSelected() {
    const newPayMethod = this.newCreditCardPayMethod();
    newPayMethod.country = this.state.defaultCountry;
    this.props.clearErrors();

    if (this.stripeEnabled()) {
      this.setNewPayMethod(newPayMethod);
      this.props.createStripeIntent(this.props.account);
    } else {
      this.setNewPayMethod(newPayMethod, this.configureCreditCardClient);
    }
  }

  checkTabSelected() {
    const newPayMethod = this.newCheckPayMethod();
    this.props.clearErrors();
    this.setNewPayMethod(newPayMethod);
  }

  plastiqTabSelected() {
    const newPayMethod = this.newPlastiqPayMethod();
    this.props.clearErrors();
    this.setNewPayMethod(newPayMethod);
  }

  handleBlur(e) {
    if (e.target.name == "ach_routing_num" && e.target.value.length < 8) {
      this.setState({ routingErrorClass: "has-error" });
    }
    if (e.target.name == "ach_account_num" && e.target.value.length < 1) {
      this.setState({ accountNumErrorClass: "has-error" });
    }
    if (e.target.name == "ach_account_num_confirmation" && e.target.value.length < 1) {
      this.setState({ accountNumConfErrorClass: "has-error" });
    }
  }

  handleFieldChange(e) {
    const regex = /^[0-9-]+$/;
    if (e.target.name == "ach_routing_num" || e.target.name == "ach_account_num" || e.target.name == "ach_account_num_confirmation") {
      if (e.target.name == "ach_routing_num" && e.target.value.length > 7) {
        this.setState({ routingErrorClass: null });
      }
      if (e.target.name == "ach_account_num" && e.target.value.length > 0) {
        this.setState({ accountNumErrorClass: null });
      }
      if (e.target.name == "ach_account_num_confirmation") {
        if (e.target.value.length > 0 && e.target.value == this.state.newPayMethod.ach_account_num) {
          this.setState({ accountNumConfErrorClass: null });
        } else if (e.target.value.length > 0 && e.target.value != this.state.newPayMethod.ach_account_num) {
          this.setState({ accountNumConfErrorClass: "has-error" });
        }
      }
      if (regex.test(e.target.value)) {
        this.setNewPayMethod({
          ...this.state.newPayMethod,
          [e.currentTarget.name]: e.currentTarget.value
        });
      } else {
        let string = e.target.value.replace(/\D/g, '');
        this.setNewPayMethod({
          ...this.state.newPayMethod,
          [e.currentTarget.name]: string
        });
      }
    } else {
      this.setNewPayMethod({
        ...this.state.newPayMethod,
        [e.currentTarget.name]: e.currentTarget.value
      });
    }
  }

  handleCountryChange(e) {
    this.setNewPayMethod({
      ...this.state.newPayMethod,
      country: e.currentTarget.value,
      state: ''
    });
  }

  registerNewPayMethod(onSuccess) {
    this.props.clearErrors();

    if (this.isCreditCard(this.state.newPayMethod)) {
      if (this.stripeEnabled()) {
        this.refs.StripeForm.handleSubmit(onSuccess);
      } else {
        setTimeout(() => {
          this.registerCreditCardSuccess = onSuccess;
          this.registerCreditCard();
        }, 1);
      }
    }
    else {
      onSuccess(this.state.newPayMethod);
    }
  }

  // ACH
  newAchPayMethod() {
    return {
      type: "AchPayMethod",
      ach_account_type: "Checking",
      ach_routing_num: '',
      ach_account_num: '',
      ach_account_num_confirmation: '',
      nickname: '',
      payee_name: ''
    };
  }

  checkHelpModal() {
    return (
      <ImageModal
        imgDescription={this.props.content.example_check_image_description}
        imgAlt={this.props.content.example_check_image_alt}
        isOpen={this.state.isCheckHelpModalOpen}
        image={this.props.content.example_check_image_url}
        toggle={this.toggleCheckHelpModal} />
    );
  }

  toggleCheckHelpModal() {
    this.setState({ isCheckHelpModalOpen: !this.state.isCheckHelpModalOpen });
  }

  disclosureLanguagueNotice() {
    if (this.props.content.disclosure_language_notice) {
      return (
        <ReactMarkdown
          escapeHtml={false}
          source={this.props.content.disclosure_language_notice}
          className="disclosure-language-notice"
          containerTagName="small" />
      );
    }
  }

  plastiqNotice() {
    const isPlastiq = this.isPlastiq(this.state.newPayMethod);
    const plastiqNotice = this.props.content.plastiq_pay_method_notice;
    if (isPlastiq && plastiqNotice) {
      return (
        <ReactMarkdown
          source={plastiqNotice}
          escapeHtml={false}
          className="plastiq-pay-method-notice"
          containerTagName="small" />
      );
    }
  }

  stripeForm() {
    if (!this.isCreditCard(this.state.newPayMethod)) return;

    if (this.props.stripeIntent) {
      const stripeKey = this.props.config.pay_method_config.stripe_publishable_key;
      const stripePromise = loadStripe(stripeKey);

      return (
        <Elements stripe={stripePromise}>
          <StripeForm
            ref="StripeForm"
            payMethod={this.state.newPayMethod}
            stripeIntent={this.props.stripeIntent}
            onError={this.props.onError}
            clearErrors={this.props.clearErrors}
          />
        </Elements>
      );
    }
    else {
      return (
        <Spinner centered />
      );
    }
  }

  achFields() {
    if (this.isAch(this.state.newPayMethod)) {
      return (
        <div>
          {this.nicknameField()}
          {this.payeeNameField()}
          {this.achAccountTypeField()}
          {this.achRoutingNumberField()}
          {this.achAccountNumberField()}
          {this.achAccountConfirmationField()}
          {this.disclosureLanguagueNotice()}
        </div>
      );
    }
  }

  achAccountTypeField() {
    const checkingId = "ach_account_type_checking";
    const savingsId = "ach_account_type_savings";

    return (
      <div className="form-group row">
        <div className={`${this.props.labelClass} py-0`}>
          {this.props.i18n.portal.payer.payment.account_type}
        </div>

        <div className={`${this.props.fieldClass}`}>
          <div className="form-check form-check-inline">
            <input
              id={checkingId}
              name="ach_account_type"
              type="radio"
              value="Checking"
              className="form-check-input"
              required="required"
              checked={this.state.newPayMethod.ach_account_type === "Checking"}
              onChange={this.handleFieldChange} />
            <label className="form-check-label" htmlFor={checkingId}>
              {this.props.i18n.common.checking}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              id={savingsId}
              name="ach_account_type"
              type="radio"
              value="Savings"
              className="form-check-input"
              required="required"
              checked={this.state.newPayMethod.ach_account_type === "Savings"}
              onChange={this.handleFieldChange} />
            <label className="form-check-label" htmlFor={savingsId}>
              {this.props.i18n.common.savings}
            </label>
          </div>
        </div>
      </div>
    );
  }

  classWithErrors(fieldClassState) {
    const classes = ['form-group', 'row'];
    if (fieldClassState) classes.push(fieldClassState);
    return classes.join(' ');
  }

  achRoutingNumberField() {
    const classWithErrors = this.classWithErrors(this.state.routingErrorClass);
    return (
      <div className={classWithErrors}>
        <label className={this.props.labelClass} htmlFor="ach_routing_num">
          {this.props.content.ach_routing_num_label}
        </label>
        <div className={this.props.fieldClass}>
          <div className="input-group">
            <input
              autoComplete="off"
              className="form-control"
              name="ach_routing_num"
              placeholder={this.props.config.ach_routing_example}
              type="text"
              required="required"
              maxLength="9"
              value={this.state.newPayMethod.ach_routing_num}
              onChange={this.handleFieldChange}
              onBlur={this.handleBlur} />
            {this.checkHelpLink()}
          </div>
        </div>
      </div>
    );
  }

  achAccountNumberField() {
    const classWithErrors = this.classWithErrors(this.state.accountNumErrorClass);
    return (
      <div className={classWithErrors}>
        <label className={this.props.labelClass} htmlFor="ach_account_num">
          {this.props.content.ach_account_num_label}
        </label>
        <div className={this.props.fieldClass}>
          <div className="input-group">
            <input
              autoComplete="off"
              className="form-control"
              name="ach_account_num"
              type="text"
              required="required"
              value={this.state.newPayMethod.ach_account_num}
              onChange={this.handleFieldChange}
              onBlur={this.handleBlur} />
            {this.checkHelpLink()}
          </div>
        </div>
      </div>
    );
  }

  checkHelpLink() {
    return (
      <div className="input-group-append">
        <Link
          className="btn btn-outline-primary"
          onClick={this.toggleCheckHelpModal}
          tabIndex="-1">
          <i className="fa icon-help" />
        </Link>
      </div>
    );
  }

  achAccountConfirmationField() {
    const classWithErrors = this.classWithErrors(this.state.accountNumConfErrorClass);
    return (
      <div className={classWithErrors}>
        <label className={this.props.labelClass} htmlFor="ach_account_num_confirmation">
          {this.props.content.confirm_ach_account_num_label}
        </label>
        <div className={this.props.fieldClass}>
          <input
            autoComplete="off"
            className="form-control"
            name="ach_account_num_confirmation"
            type="text"
            required="required"
            value={this.state.newPayMethod.ach_account_num_confirmation}
            onChange={this.handleFieldChange}
            onBlur={this.handleBlur} />
        </div>
      </div>
    );
  }

  nicknameField() {
    if (this.props.showNicknameField) {
      return (
        <div className="form-group row">
          <label className={this.props.labelClass} htmlFor="nickname">
            {this.props.i18n.common.nickname}
          </label>
          <div className={this.props.fieldClass}>
            <input
              autoComplete="off"
              className="form-control"
              name="nickname"
              type="text"
              value={this.state.newPayMethod.nickname}
              onChange={this.handleFieldChange} />
          </div>
        </div>
      );
    }
  }

  payeeNameField() {
    if (this.props.config.pay_method_config.show_pay_method_payee_field) {
      return (
        <div className="form-group row">
          <label className={this.props.labelClass} htmlFor="payee_name">
            {this.props.i18n.common.name_on_account}
          </label>
          <div className={this.props.fieldClass}>
            <input
              autoComplete="off"
              className="form-control"
              name="payee_name"
              type="text"
              value={this.state.newPayMethod.payee_name}
              onChange={this.handleFieldChange} />
          </div>
        </div>
      );
    }
  }

  // --- CREDIT CARD
  creditCardConfig() {
    const config = this.props.config.pay_method_config;
    return {
      callback: this.handleCreditCardResponse,
      paypageId: config.vantiv_paypage_id,
      reportGroup: config.vantiv_report_group,
      merchant_id: config.cc_merchant_id,
      registration_endpoint: config.cc_registration_endpoint,
      showCvv: true,
      timeout: '15000',
      style: 'dadestyles6',
      height: '200px',
      div: 'payframe',
      numYears: 8,
      months: this.ccMonths(),
      tooltipText: 'A CVV is the 3 digit code on the back of your card',
      tabIndex: {
        cvv: 1,
        accountNumber: 2,
        expMonth: 3,
        expYear: 4
      },
      placeholderText: {
        cvv: 'CVV',
        accountNumber: 'Account Number'
      }
    };
  }

  ccMonths() {
    return {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    };
  }

  registerCreditCard() {
    this.creditCardClient.registerCard({
      ...this.state.newPayMethod,
      id: this.props.config.pay_method_config.cc_merchant_id,
      orderId: moment().valueOf()
    });
  }

  handleCreditCardResponse(response) {
    if (response.message == 'Success') {
      const cardData = this.creditCardClient.formatResponse(response);
      const newPayMethod = {
        ...this.state.newPayMethod,
        ...cardData
      };
      delete newPayMethod.credit_card_number;
      delete newPayMethod.ccv_number;
      delete newPayMethod.cc_expiration;
      this.registerCreditCardSuccess(newPayMethod);
    }
    else {
      const error = this.props.i18n.credit_card.error_messages[response.response];
      this.props.onError(error);
    }
  }

  configureCreditCardClient() {
    const config = this.creditCardConfig();
    this.creditCardClient = new CreditCardClient(config); // eslint-disable-line no-undef
  }

  newCreditCardPayMethod() {
    return {
      type: "CreditCardPayMethod",
      nickname: '',
      name_on_card: '',
      address_line_1: '',
      address_line_2: '',
      country: 'US',
      state: '',
      city: '',
      zip_code: '',
      credit_card_number: '',
      ccv_number: '',
      cc_expiration: ''
    };
  }

  creditCardFields() {
    if (this.stripeEnabled()) {
      return (
        this.stripeForm()
      );
    } else if (this.isCreditCard(this.state.newPayMethod)) {
      return (
        <div>
          {this.ccGeneralFieldset()}
          {this.ccBillingFieldset()}
          {this.ccCardInfoFieldset()}
        </div>
      );
    }
  }

  ccGeneralFieldset() {
    if (this.props.showNicknameField) {
      return (
        <fieldset>
          <legend>
            {this.props.i18n.common.general}
          </legend>
          {this.nicknameField()}
        </fieldset>
      );
    }
  }

  ccBillingFieldset() {
    return (
      <fieldset>
        <legend>
          {this.props.i18n.portal.billing.billing_information}
        </legend>
        {this.ccNameOnCardField()}
        {this.ccAddress1Field()}
        {this.ccAddress2Field()}
        {this.ccCountryField()}
        {this.ccStateField()}
        {this.ccCityField()}
        {this.ccZipCodeField()}
      </fieldset>
    );
  }

  ccCardInfoFieldset() {
    return (
      <fieldset>
        <legend>
          {this.props.i18n.portal.billing.credit_card}
        </legend>
        {this.ccCardInfoFields()}
      </fieldset>
    );
  }

  ccCardInfoFields() {
    if (this.props.config.pay_method_config.cc_iframe_enabled) {
      return <div id="payframe"></div>;
    }
    else {
      return (
        <div>
          {this.ccCardNumberField()}
          {this.ccVerificationNumberField()}
          {this.ccExpirationField()}
        </div>
      );
    }
  }

  ccCardNumberField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass}>
          {this.props.content.credit_card_number_label}
        </label>
        <div className={this.props.fieldClass}>
          <MaskedInput
            mask="1111 1111 1111 1111"
            className="form-control"
            name="credit_card_number"
            type="text"
            required="required"
            value={this.state.newPayMethod.credit_card_number}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccVerificationNumberField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass}>
          {this.props.content.credit_card_security_number_label}
        </label>
        <div className={this.props.fieldClass}>
          <MaskedInput
            mask="1111"
            size="4"
            className="form-control"
            name="ccv_number"
            type="text"
            required="required"
            value={this.state.newPayMethod.ccv_number}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccExpirationField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass}>
          {this.props.content.credit_card_expiration_label}
        </label>
        <div className={this.props.fieldClass}>
          <MaskedInput
            mask="11/1111"
            placeholder="MM/YYYY"
            className="form-control"
            name="cc_expiration"
            type="text"
            required="required"
            value={this.state.newPayMethod.cc_expiration}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccNameOnCardField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="name_on_card">
          {this.props.i18n.portal.billing.name_on_card}
        </label>
        <div className={this.props.fieldClass}>
          <input
            className="form-control"
            name="name_on_card"
            type="text"
            required="required"
            value={this.state.newPayMethod.name_on_card}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccAddress1Field() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="address_line_1">
          {this.props.i18n.portal.billing.street_address_1}
        </label>
        <div className={this.props.fieldClass}>
          <input
            className="form-control"
            name="address_line_1"
            type="text"
            required="required"
            value={this.state.newPayMethod.address_line_1}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccAddress2Field() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="address_line_2">
          {this.props.i18n.portal.billing.street_address_2}
        </label>
        <div className={this.props.fieldClass}>
          <input
            className="form-control"
            name="address_line_2"
            type="text"
            required="required"
            value={this.state.newPayMethod.address_line_2}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccCountryField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="country">
          {this.props.i18n.portal.billing.country}
        </label>
        <div className={this.props.fieldClass}>
          <select
            className="form-control"
            name="country"
            value={this.state.newPayMethod.country}
            onChange={this.handleCountryChange}>
            {this.countryOptions()}
          </select>
        </div>
      </div>
    );
  }

  countryOptions() {
    const countries = this.props.config.location.countries;
    return countries.map((country, i) =>
      <option key={country.code} value={country.code}>
        {country.name}
      </option>
    );
  }

  getBillingLabelByOperationCountry(label) {
    //If it's using portal in English but the main operation country is not US
    if (this.props.config.locale == 'en' && this.state.defaultCountry !== 'US') {
      return this.props.i18n.portal.billing[`${label}_${this.state.defaultCountry.toLowerCase()}`];
    }
    return this.props.i18n.portal.billing[`${label}`];
  }

  ccStateField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="state">
          {this.getBillingLabelByOperationCountry('state')}
        </label>
        <div className={this.props.fieldClass}>
          <select
            className="form-control"
            name="state"
            value={this.state.newPayMethod.state}
            onChange={this.handleFieldChange}>
            <option></option>
            {this.stateOptions()}
          </select>
        </div>
      </div>
    );
  }

  stateOptions() {
    const country = this.props.config.location.countries.find((country) => {
      return country.code == this.state.newPayMethod.country;
    });
    const states = country['states'];
    return states.map((state, i) =>
      <option key={state.code} value={state.code}>
        {state.name}
      </option>
    );
  }

  ccCityField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="city">
          {this.props.i18n.portal.billing.city}
        </label>
        <div className={this.props.fieldClass}>
          <input
            className="form-control"
            name="city"
            type="text"
            required="required"
            value={this.state.newPayMethod.city}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  ccZipCodeField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="zip_code">
          {this.getBillingLabelByOperationCountry('zip_code')}
        </label>
        <div className={this.props.fieldClass}>
          <input
            className="form-control"
            name="zip_code"
            type="text"
            required="required"
            value={this.state.newPayMethod.zip_code}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }
  // ---

  // --- CHECK
  newCheckPayMethod() {
    return {
      type: "CheckPayMethod"
    };
  }

  checkFields() {
    if (this.isCheck(this.state.newPayMethod)) {
      return (
        <div>
          <div className="form-group">
            {this.checkFrontButton()}
          </div>
          <div className="form-group">
            {this.checkRearButton()}
          </div>
        </div>
      );
    }
  }

  checkFrontButton() {
    const iconType = this.state.newPayMethod.checkFront ? "check" : "camera";
    return (
      <label className="btn btn-default btn-file btn-block" htmlFor="checkFront">
        <Icon type={iconType} />{' '}
        Check Front
        <input
          type="file"
          name="checkFront"
          className="d-none"
          onChange={this.handleCheckImage}
          accept="image/*"
          capture="camera" />
      </label>
    );
  }

  checkRearButton() {
    const iconType = this.state.newPayMethod.checkRear ? "check" : "camera";
    return (
      <label className="btn btn-default btn-file btn-block" htmlFor="checkRear">
        <Icon type={iconType} />{' '}
        Check Rear
        <input
          type="file"
          name="checkRear"
          className="d-none"
          onChange={this.handleCheckImage}
          accept="image/*"
          capture="camera" />
      </label>
    );
  }

  handleCheckImage(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    const imageType = e.target.name;

    reader.onload = (upload) => {
      this.setNewPayMethod({
        ...this.state.newPayMethod,
        [imageType]: upload.target.result
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  // --- PLASTIQ
  newPlastiqPayMethod() {
    return {
      type: "PlastiqPayMethod",
      email: '',
    };
  }

  emailField() {
    return (
      <div className="form-group row">
        <label className={this.props.labelClass} htmlFor="email">
          {this.props.i18n.common.email}
        </label>
        <div className={this.props.fieldClass}>
          <input
            autoComplete="off"
            className="form-control"
            name="email"
            type="text"
            value={this.state.newPayMethod.email}
            onChange={this.handleFieldChange} />
        </div>
      </div>
    );
  }

  plastiqFields() {
    if (this.isPlastiq(this.state.newPayMethod)) {
      return (
        <div>
          {this.emailField()}
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.tabs()}
        {this.achFields()}
        {this.creditCardFields()}
        {this.checkFields()}
        {this.plastiqFields()}
        {this.checkHelpModal()}
      </div>
    );
  }
}

PayMethodForm.defaultProps = {
  showNicknameField: true
};

PayMethodForm.propTypes = {
  newPayMethod: PropTypes.object,
  creditCardEnabled: PropTypes.bool,
  plastiqEnabled: PropTypes.bool,
  stripeEnabled: PropTypes.bool,
  showNicknameField: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  labelClass: PropTypes.string.isRequired,
  fieldClass: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  abilities: PropTypes.object,
  stripeIntent: PropTypes.string,
  createStripeIntent: PropTypes.func,
  account: PropTypes.object,
  oneTimePayment: PropTypes.bool,
};

export default PayMethodForm;
